import gsap from 'gsap'
import _ from 'lodash';
import {DateTime} from 'luxon';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const truncate = (fullStr, strLen) => {
    if ((fullStr?.length || 0) <= strLen) {
        return fullStr
    }

    const separator = '...'

    const sepLen = separator.length,
        charsToShow = strLen - sepLen,
        frontChars = Math.ceil(charsToShow/2),
        backChars = Math.floor(charsToShow/2)

    return fullStr.substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars)
}

const numberWithCommas = (n) => {
    let parts = n.toString().split(".");
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


let counter = { var: 0 }

const animateText = (item, amount) => {
    if (!_.isNull(item.innerHTML)) {
        gsap.fromTo(counter,{var: 0}, {
            duration: 2,
            var: amount,
            onUpdate: function () {
                item.innerHTML = numberWithCommas(counter.var)
            },
        })
    }
}

const formatNumber = (value, decimals = 2) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    })

    return formatter.format(value)
}

const toPercentage = (value) =>
    (value || 0).toLocaleString('en', { style: 'percent', sign: true, minimumFractionDigits: 2 })

const convertToInternationalCurrencySystem = (labelValue, fractionDigits = 1) => {

    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

        ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(fractionDigits) + "B"
        // Six Zeroes for Millions
        : Math.abs(Number(labelValue)) >= 1.0e+6

            ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(fractionDigits) + "M"
            // Three Zeroes for Thousands
            : Math.abs(Number(labelValue)) >= 1.0e+3

                ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(fractionDigits) + "K"

                : Math.abs(Number(labelValue)).toFixed(2)

}

const formatTime = (date) => {
    const d = DateTime.fromMillis(date)
    return d.toLocaleString(DateTime.TIME_24_WITH_SECONDS)//date.toRelativeCalendar()
}

const formatDate = (date) => {
    const d = DateTime.fromISO(date)
    return d.toFormat('dd MMM yyyy')//date.toRelativeCalendar()
}

const getModalInfo = (type, data) => (!!data) ? data.filter((item) => item.position === type)?.[0] : null

function toFixed(value, precision = 2) {
    var regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${precision}})?`)
    var with2Decimals = value.toString().match(regex)[0];
    return with2Decimals;
}

function formatPrice(inputString) {
    let integerPart = ''
    let decimalPart = ''
    if (inputString?.length > 18) {
        decimalPart = inputString.slice(inputString?.length - 18, inputString?.length)
        integerPart = inputString.slice(0, inputString?.length - 18)
    }
    return integerPart + '.' + decimalPart
}


export {
    classNames,
    truncate,
    animateText,
    formatNumber,
    convertToInternationalCurrencySystem,
    formatTime,
    formatDate,
    toPercentage,
    getModalInfo,
    toFixed,
    formatPrice
}
