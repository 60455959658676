import {DateTime} from 'luxon';
import {ReactComponent as Arrow} from '../../assets/svg/arrow-right.svg'
import report from '../../assets/img/report.png'
import {Link} from 'react-router-dom';

const TradeReport = ({title, timestamp, url, latest, index}) => {

    return (
        <Link to={url} id={`trading_button_${index}`} target="_blank" rel="noreferrer" className="trade-report cursor-pointer">
            {!latest ?
                <div className="flex flex-row py-5 justify-between border-t border-grey-400">
                    <h2 className="font-sans text-input text-grey-200">
                        {`${title} - ${DateTime.fromISO(timestamp).toFormat('MMMM yyyy')}`}
                    </h2>
                    <Arrow/>
                </div> :
                <div className="flex flex-row py-5 justify-start">
                    <img src={report} className="w-[88px]" alt="trading report cover"/>
                    <div className="flex-col pl-4 w-full justify-between h-[112px] relative">
                        <div className="h-full">
                            <span className="text-grey-200/50 text-input-sm">{title}</span>
                            <h2 className="font-sans text-input text-grey-200">
                                {DateTime.fromISO(timestamp).toFormat("MMMM yyyy")}
                            </h2>
                        </div>

                        <Arrow className="absolute bottom-0 right-0"/>
                    </div>

                </div>
            }
        </Link>
    )
}

export default TradeReport
