const dev = {
    "api_url": "https://jnh53daoa2.execute-api.eu-west-1.amazonaws.com/prod",
}

const test = {
    "api_url": "https://d3a1qyp8fl3064.cloudfront.net",
}

const prod = {
    "api_url": "https://d13s37xd3umzwd.cloudfront.net",
}

const RFRM_DEV_URL = "https://api.hfd.dev.growity.app/"
const RFRM_PROD_URL = "https://pubapi.reformdao.com/"

const RFRMAPI = (env) => {
    switch (process.env.REACT_APP_HOST_ENV) {
        case 'prod':
            return prod.api_url
        case 'test':
            return test.api_url
        default:
            return dev.api_url
    }
}

const craftAPI = "https://cms.reformdao.com/api"

export {
    RFRMAPI,
    RFRM_DEV_URL,
    RFRM_PROD_URL,
    craftAPI
}
