import {createContext, useContext, useEffect, useMemo, useState} from 'react'
import {apiCall} from '../api'
import {useMutation} from '@tanstack/react-query'
// import {useWeb3} from './web3'
import { BrowserProvider, ethers } from 'ethers'
// import Web3 from 'web3'
import {useDisconnect, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider} from '@web3modal/ethers/react'

const Auth = createContext(null)

const emptyToken = {
    access: null,
    refresh: null,
    expiry: null,
}

const AuthProvider = ({ children }) => {
    const { open } = useWeb3Modal()
    const { disconnect } = useDisconnect()
    const { address, isConnected } = useWeb3ModalAccount()
    const { walletProvider } = useWeb3ModalProvider()
    //const { web3 } = useWeb3()
    const [user, setUser] = useState({username: null})
    const [rates, setRates] = useState(null)
    const [tokens, setTokenState] = useState({...emptyToken, set: () => {} })
    const [session, setSession] = useState(null)
    const [nonce, setNonce] = useState(null)
    const [authenticating, setAuthenticating] = useState(false)

    useEffect(() => {
        const bootstrapAsync = async () => {
            let tokens
            try {
                tokens = localStorage.getItem('tokens')
                if (!!tokens) {
                    await setTokens(JSON.parse(tokens))
                    // if (!isConnected)
                    //     await open()
                }
            } catch (e) {
                //console.log(e)
            }
            // After restoring token, we may need to validate it in production apps
            // This will switch to the App screen or Auth screen and this loading
            // screen will be unmounted and thrown away.
        };

        bootstrapAsync()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const auth = async () => {
            if (!!session && !!nonce && !isAuthenticated) {
                await authenticate()
            }
        }
        auth()
        // eslint-disable-next-line
    }, [session, nonce])

    useEffect(() => {
        ;(async () => {
            if (!!tokens?.access && !user?.username) {
                try {
                    await fetchUser()
                } catch (e) {}
            }
        })()
        // eslint-disable-next-line
    }, [tokens.access, user?.username])

    useEffect(() => {
        if (address && user?.address && address !== user?.address) {
            clear()
        }
    }, [address])

    const fetchUser = async () => {
        const { address, balances, username, rates, contracts } = await apiCall('/me', {
            method: 'GET'
        }, tokens)

        setUser({
            username,
            address,
            rfrm: ethers.formatEther(balances?.rfrm),//balances.RFRM / 10 ** 18,
            erfrm: ethers.formatEther(balances?.erfrm), //balances.eRFRM / 10 ** 18,
            lp: ethers.formatEther(balances?.lp),//balances.lp / 10 ** 18,
            nft: balances?.nft || [],
            contracts: contracts || [],
        })
        setRates(rates)
    }

    /**
     * Store auth info in local storage
     * @param data
     */
    const setTokens = async (data) => {
        setTokenState({...data, clear, set: setTokens})
        await localStorage.setItem('tokens', JSON.stringify(data))
    }

    /**
     * Clear auth info from local storage
     * @returns {Promise<void>}
     */
    const clear = async () => {
        await localStorage.removeItem('tokens')
        setTokens(emptyToken)

        await disconnect()
        setUser(null)
    }

    /**
     * Authenticate user via nonce
     * @returns {Promise<unknown>}
     */
    const authenticate = async () => {
        const ethersProvider = new BrowserProvider(walletProvider)
        const signer = await ethersProvider.getSigner()

        // if (address && nonce) {
            const signature = await signer.signMessage(nonce) //web3?.eth.personal.sign(Web3.utils.fromUtf8(nonce), address)
            const resp = await apiCall('/auth/sign', {
                method: 'post',
                payload: { address, signature, session }
            })

            const tokens = {
                refresh: resp.refresh_token,
                access: resp.access_token,
                expiry: resp.expires_at,
            }
            await setTokens(tokens)
        //}
    }

    /**
     * Login and store tokens
     * @param data
     * @returns {Promise<void>}
     */
    const login = useMutation( (data) =>
        apiCall('/auth', {
                payload: data,
                method: 'POST',
            }),{
            onMutate: async (data) => {
                //console.log('onMutate:', data)
            },
            onSuccess: (data, variables, context) => {
                setSession(data?.session)
                setNonce(data?.nonce)
                setAuthenticating(false)
            },
            onError: (error, variables, context) => {
                //console.log('error:', error, variables, context)
            },
            onSettled: (data, error, variables, context) => {
            }
        })

    const logout = async () => {
        clear()
    }

    const isAuthenticated = useMemo(() =>
        !!tokens.access && !!tokens.refresh && !!tokens.expiry && tokens.expiry > Date.now()/1000
    , [tokens])

    return (
        <Auth.Provider
            value={{
                tokens,
                setTokens,
                user,
                rates,
                login,
                logout,
                fetchUser,
                authenticating,
                isAuthenticated,
            }}
        >
            {children}
        </Auth.Provider>
    )
}

const useAuth = () => {
    const context = useContext(Auth)
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider')
    }
    return context
}

export { useAuth, AuthProvider }
