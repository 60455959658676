import { useField } from 'formik'

const FormItem = ({ label, ...props }) => {
    const [field, meta] = useField(props)
    const { type, borderless } = props
    const b = borderless ? '' : 'border-t-[1px]'
    return (
        <div className={`w-full flex items-center justify-between ${b} border-grey-300 pt-2 mb-4`}>
            <label htmlFor={field.name} className="text-label uppercase">{label}</label>
            {type === 'textarea'
                ? <textarea className={field.className ? field.className : "form-item"} {...field} {...props} />
                : <input
                    {...field}
                    {...props}
                    className={field.className
                        ? field.className
                        : "border-none shadow-none focus:outline-offset-0 focus:border-none font-mono text-input tracking-wider disabled:bg-grey-300/25 disabled:text-grey-400 focus:outline-primary px-4 py-[9px] w-7/12 bg-grey-400 text-grey-100"}
                    autoCorrect="off"
                    autoCapitalize="none"
                />}
            {meta.touched && meta.error ? <span className="text-primary text-2xs">{meta.error}</span> : null}
        </div>
    )
}

export default FormItem
