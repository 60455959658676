import {Link, useLocation} from 'react-router-dom';

const NewsItem = ({title, author, timestamp, url, index}) => {
    const location = useLocation()
    const getId = () => {
        const pathname = location.pathname === '/' ? 'overview' : location.pathname.slice(1)
        return `${pathname}_news_button_${index}`
    }

    return (
        <div className="flex flex-col mb-4 px-8">
            <Link to={url} id={getId()} target="_blank" rel="noreferrer" className="cursor-pointer">
                <h2 className="hover:text-primary font-sans text-input text-grey-200">{title}</h2>
            </Link>
            <div className={"flex flex-row mt-1"}>
                <span className="text-grey-200/50 text-label">{author}</span>
                <div className="mx-2 text-grey-200/50 text-label">•</div>
                <span className="text-grey-200/50 text-label">{timestamp}</span>
            </div>
        </div>
    )
}

export default NewsItem
