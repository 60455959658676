import NavigationBar from './components/menu/NavigationBar'
import Menu from './components/menu/Menu'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useWeb3, NotificationTopic } from './utils/hooks/web3'
import Account from './components/account/Account'
import routes from './config/routes'
import { useAuth } from './utils/hooks/auth'
import { useStaking } from './utils/hooks/staking'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import Button from './components/Button'
import { useTranslation } from 'react-i18next'
import MobileMenu from './components/menu/MobileMenu'
import useModal from './utils/hooks/useModal'
import { ReactComponent as Hamburger } from './assets/svg/hamburger.svg'
import Auth from './pages/Auth'
import Reports from './components/news/Reports'
import News from './components/news/News'
import BondsDialog from './components/bonds/BondsDialog'

const App = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { fetchUser, isAuthenticated } = useAuth()
    const bonds = useModal()
    const { subscribeToEvents, setNotifier } = useWeb3()
    const { listPools, listStakes } = useStaking()
    let location = useLocation()
    const { isOpen, openModal, closeModal } = useModal()

    const poolQuery = listPools()
    const stakesQuery = listStakes()

    const handleTopics = (topic) => {
        console.log(topic, location.pathname)
        switch (topic) {
            case NotificationTopic.DEPOSIT:
                location.pathname === '/staking'
                    ? toast.success(t('toast_deposit_success'))
                    : toast.success(
                          <div className="flex flex-col">
                              <span>{t('toast_deposit_bonds_success')}</span>
                              <Button
                                  className="bg-primary hover:bg-primary hover:brightness-90 mt-4"
                                  onClick={() => {
                                      toast.dismiss()
                                      navigate('/staking')
                                  }}
                              >
                                  View Position
                              </Button>
                          </div>,
                          {
                              duration: 10000,
                          },
                          {
                              id: 'deposit',
                          }
                      )
                poolQuery.refetch()
                break
            case NotificationTopic.WITHDRAW:
                toast.success(t('toast_withdraw_success'))
                poolQuery.refetch()
                break
            case NotificationTopic.CLAIM:
                toast.success(t('toast_reward_claimed_success'))
                poolQuery.refetch()
                break
            case NotificationTopic.PURCHASE:
                toast.success(t('toast_bonds_purchase_success'))
                break
            default:
                break
        }
        stakesQuery.refetch()
        fetchUser()
    }

    useEffect(() => {
        subscribeToEvents()
        if (isAuthenticated) {
            setNotifier(handleTopics)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, handleTopics])

    const handleClick = () => bonds.openModal()

    return (
        <>
            <>
                <div className="grid grid-row-2 grid-rows-[4rem_1fr] h-full">
                    <NavigationBar>
                        <Menu items={routes} />
                        {location.pathname !== '/bonds' && (
                            <Button
                                className="text-grey-100 hover:text-grey-500 px-4 py-2 mr-[14px] bg-primary"
                                onClick={handleClick}
                            >
                                {t('menu_buy_bonds')}
                            </Button>
                        )}
                        <Button
                            className="block sm:hidden bg-transparent"
                            onClick={openModal}
                        >
                            <Hamburger className="w-6 h-6" />
                        </Button>
                        {/*<span className="mr-5 text-xs text-gray-200">v{packageJson.version}</span>*/}
                    </NavigationBar>
                    <Routes>
                        {routes.map((route) => {
                            let routeElement = route.element

                            if (route.useLayout)
                                routeElement = (
                                    <div className="flex flex-col sm:block lg:grid lg:grid-cols-[minmax(200px,_1fr)_32rem] md:grid-row-1 sm:overflow-auto">
                                        {route.element}
                                        <Account className="md:order-2 order-1" />
                                        <div className="w-full h-full flex-col md:hidden flex order-3">
                                            {location.pathname ===
                                            '/trading' ? (
                                                <Reports />
                                            ) : (
                                                <News />
                                            )}
                                        </div>
                                    </div>
                                )
                            return (
                                <Route
                                    key={route.path}
                                    path={route.path}
                                    exact={route.exact}
                                    element={routeElement}
                                />
                            )
                        })}
                    </Routes>
                </div>
                <MobileMenu
                    items={routes}
                    isOpen={isOpen}
                    onClose={closeModal}
                />
                <BondsDialog isOpen={bonds.isOpen} onClose={bonds.closeModal} />
            </>
        </>
    )
}

export default App
