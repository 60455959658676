import {ReactComponent as Spinner} from '../assets/svg/spinner.svg'

const ErrorMessage = ({callback}) => (
    <div className="flex items-center justify-center w-full h-full flex-col">
        <span className="text-title text-grey-100">An error occurred will fetching.</span>
        <span
            onClick={callback}
            className="hover:text-primary retry cursor-pointer mt-4 text-input-sm text-grey-200 font-mono uppercase tracking-widest flex flex-row items-center justify-center">
            <Spinner className="mr-2"/>Retry
        </span>
    </div>
)

export default ErrorMessage
