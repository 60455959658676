import gsap from 'gsap'
import {Fragment, useEffect} from 'react'
import {NavLink} from 'react-router-dom'
import {classNames} from '../../utils/helper'
import { ReactComponent as Logo } from '../../assets/svg/logo.svg'
import {Dialog, Transition} from '@headlessui/react'
import { ReactComponent as Close } from '../../assets/svg/close-gray.svg'

const MobileMenu = ({items, onClose, isOpen}) => {
    useEffect(() => {
        const tl = gsap.timeline({paused: false})
        tl.fromTo('.menu-item', {
            duration: 1,
            opacity: 0,
            x: 75,
            stagger: 0.15,
            ease: 'power3.out'
        }, {opacity: 1, x: 0})
        tl.play()
    }, [])

    const Item = ({item, index}) =>
        <div key={item.title} className={`flex flex-col border-t border-[#484848] w-full h-[66px]`}>
            <NavLink
                end
                to={item.path}
                onClick={onClose}
                className={({isActive}) => {
                    return classNames(
                        isActive ? 'text-primary' : 'text-[#B2B1AD]',
                        'block w-full transition-all duration-200 hover:text-primary font-sans pt-1 text-[24px] font-light text-left'
                    )
                }}
            >
                {item.title}
            </NavLink>
        </div>

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[100]" onClose={onClose}>

                <Transition.Child
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-70"/>
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full min-w-full text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="flex-1">
                                <div className="flex flex-col flex-1 items-start w-full h-full px-8 bg-grey-600 pt-[68px]">
                                    <div className={`flex flex-row items-center justify-between w-full h-[66px]`}>
                                        <Logo/>
                                        <button
                                            onClick={onClose}
                                            className="mt-1 ml-1 font-mono shrink-0 text-sm w-16 h-16 flex items-center justify-center">
                                            <Close className="fill-grey-100"/>
                                        </button>
                                    </div>
                                    <div className="pt-[60px] w-full">
                                        {items.map((item, index) => !!item.menuItem &&
                                            <Item item={item} index={index} key={item}/>)}
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}


export default MobileMenu
