import {useTranslation} from 'react-i18next'
import Modal from './layout/Modal'
import Button from './Button'

const ConfirmDialog = ({ onClose, isOpen, body, onConfirm, cancelId = '', confirmId= '' }) => {
    const { t } = useTranslation()

    const handleClose = () => {
        onClose()
    }

    return (
        <Modal hideCloseButton={true} customClass="w-[475px]" isOpen={isOpen} onClose={() => {
            handleClose()
        }}>
            <div className="flex flex-col text-left p-10 h-3/4 max-h-3/4 flex-1 relative bg-grey-100 text-grey-400 z-100 whitespace-pre-wrap">
                {body}
                <div className="w-full flex flex-row mt-10 space-x-4 justify-end">
                    <Button id={cancelId} type="button" className="bg-grey-100 hover:bg-grey-200 text-tertiary" onClick={handleClose}>
                        {t('dialog_cancel')}
                    </Button>
                    <Button id={confirmId} type="button" className="bg-white hover:bg-grey-200" onClick={onConfirm}>
                        {t('dialog_confirm')}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default ConfirmDialog
