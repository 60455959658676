const resources = {
    en: {
        translation: {
            title_tvl: 'Total value locked',
            title_travl: 'Total trading volume ',
            title_tokens: 'Treasury',
            title_trades: 'Live Trades',
            title_total_trades: 'Trading Stats',
            title_trading_treasury: 'Trading Treasury',
            title_buy_bonds: 'Buy Bonds',
            title_price: 'Price',
            table_title_time: 'Time',
            table_title_pair: 'Pair',
            table_title_quantity: 'Quantity',
            table_title_price: 'Price',
            table_title_action: 'Buy/Sell',
            table_title_exchange: 'Exchange',

            stats_circulating_supply: 'Circulating Supply',
            stats_market_cap: 'Market Cap',
            stats_bonding_supply: 'Bonding Supply',
            stats_staking_supply: 'Staked Supply',
            stats_hdf_treasury: 'RFRM in Treasury',
            stats_buyback_supply: 'Added to the bonding treasury',

            last_hour: 'Last Hour',
            last_24h: 'Last 24 hours',
            last_7d: 'Last 7 days',
            last_30d: 'Last 30 days',

            copy_clipboard_wallet: 'Wallet URL copied to clipboard!',
            copy_clipboard_wallet_failed:
                'Failed to copy wallet URL to clipboard!',

            error_request_accounts: 'Error occurred requesting your account(s)',
            error_no_accounts: 'No accounts found',
            error_no_wallet_to_copy: 'No wallet to copy',

            title_news: 'Highlights',
            bonds_title: 'Buy Bonds',
            bonds_description:
                'Buy RFRM with discount and lock in the current market price.',
            bonds_label_payment_method: 'Select token',
            bonds_label_lock_period: 'Select payment',
            bonds_label_amount: 'amount',
            bonds_label_rfrm_amount: 'RFRM amount',
            bonds_label_currency: 'Currency',
            bonds_label_amount_receive: 'RFRM amount to receive',
            bonds_label_period: 'Discount',
            bonds_btn_buy: 'Buy Now',

            staking_dialog_title: 'Stake your RFRM',
            staking_dialog_lp_title: 'LP Staking',
            staking_dialog_nft_title: 'Manage Your NFTs',
            staking_info_description: 'Stake your RFRM to earn more RFRM. You can stake your RFRM for 1, 3, 6, 12 months. The longer you stake, the more RFRM you will earn.',
            staking_balance: 'Your RFRM balance',
            staking_balance_lp: 'Your LP balance',
            staking_amount: 'Amount to stake',
            staking_period: 'Stake period',
            staking_lock_period: 'Discount',
            staking_unlock_date: 'Unlock date',
            staking_weight: 'Staking Weight',
            staking_btn_stake: 'Stake',
            staking_btn_unstake: 'Unstake',
            staking_btn_claim: 'Claim',
            staking_apy: 'APR',
            staking_expected_apy: 'Expected APY',
            staking_staked_nft_only: 'Only Show Staked NFTs',
            staking_overview: 'Staking Overview',
            staking_empty_message: 'You have no staked RFRM. Stake your RFRM to earn more RFRM.',
            staking_claims: 'Your Claims',
            table_staking_progress: 'Progress',

            table_staking_type: 'Type',
            table_staking_amount: 'Amount',
            table_staking_value: 'Value',
            table_staking_status: 'Status',
            table_staking_unlock_date: 'Unlock Date',
            table_staking_unlock: 'Unlock',
            table_staking_claim_date: 'Claim Date',
            table_staking_claimable: 'Claimable',
            table_staking_vesting_claimable: 'Vested',
            table_staking_claim: 'Claim',
            title_total_staked: 'Total Staked',
            title_total_lp: 'Total Liquidity',

            title_trade_reports: 'Trade Reports',

            dialog_unstake_description: 'Are you sure you want to unstake?',
            dialog_claim_description: 'Are you sure you want to unlock your vested RFRM? In case this unlock is within the 1 year lock period, you will not be able to claim all your rewards.',
            dialog_cancel: 'No, cancel',
            dialog_confirm: "Yes, I'm sure",
            dialog_fee_description: "You’re about to force unlock your stake.\n\n" +
                "Please note that since the unlock date has not yet passed, this transaction will cost a fee of:",

            menu_buy_bonds: 'Buy RFRM',
            chain_changed_correct: 'You are now connected to the correct network',
            chain_changed_incorrect: 'Current network is {{chain}}. The app requires {{requiredChain}}. Please change the network to {{requiredChain}}.',

            title_voting: 'Vote Now',

            connect_wallet: 'Connect wallet',
            connect_button: 'Connect',
            connect_to_stake: 'Connect your wallet to start staking',
            connect_to_buy_bonds: 'Connect your wallet to buy bonds',

            button_click: 'Click here',

            sign_out: 'Sign out',
            sign_out_description: 'Are you sure you want to sign out?',

            toast_deposit_success: 'Staking deposit successful',
            toast_withdraw_success: 'Removed stake successfully',
            toast_reward_claimed_success: 'Successfully claimed rewards',
            toast_bonds_purchase_success: 'Successfully purchased RFRM',
            toast_deposit_bonds_success: 'Your transaction has been verified successfully. Kindly navigate to the staking page to check your current position.',

        },
    },
}

export { resources }
