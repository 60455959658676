import gsap from 'gsap'
import {useEffect} from 'react'
import {NavLink} from 'react-router-dom'
import {classNames} from '../../utils/helper'
import {useWeb3} from '../../utils/hooks/web3'
import {useWeb3ModalAccount} from '@web3modal/ethers/react'

const Menu = ({items}) => {
    const {changeNetwork} = useWeb3()
    const { chainId, isConnected } = useWeb3ModalAccount()

    useEffect(() => {
        const tl = gsap.timeline({paused: false})
        tl.fromTo('.menu-item', {
            duration: 1,
            opacity: 0,
            x: 75,
            stagger: 0.15,
            ease: 'power3.out'
        }, {opacity: 1, x: 0})
        tl.play()
    }, [])

    return (
        <>
            {

                    <div className="w-full flex flex-row flex-1 items-center h-full mx-4">
                        {items?.map((item, index) => !!item.menuItem &&
                            <div className="menu-item hidden sm:block" key={`${index}-${item?.id}`}>
                                <NavLink
                                    end
                                    to={item?.path}
                                    className={({isActive}) => {
                                        return classNames(
                                            isActive ? 'text-primary' : 'text-gray-200',
                                            'block px-4 w-full transition-all duration-200 hover:text-primary font-sans text-sm'
                                        )
                                    }}
                                >
                                    {item?.title}
                                </NavLink>
                            </div>)}
                        {chainId?.toString() !== process.env.REACT_APP_CHAIN_ID && <div onClick={changeNetwork}
                                                                                       className="px-4 py-1 bg-tertiary text-grey-100 ml-4 cursor-pointer">{"Incorrect network"}</div>}
                    </div>
            }
            </>
    )
}


export default Menu
