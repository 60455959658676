import { classNames } from '../utils/helper'

export const ButtonVariants = {
    PRIMARY: 'primary',
    WHITE: 'white',
    BORDER: 'border',
    GHOST: 'ghost',
}

const BUTTON_STYLES = {
    [ButtonVariants.PRIMARY]: `bg-primary disabled:bg-neutral-800 disabled:text-white hover:bg-primary-dark px-6 text-grey-400 font-space py-3 rounded-[4px] text-xs uppercase tracking-wider duration-100 flex justify-center gap-3`,
    [ButtonVariants.WHITE]:
        'bg-grey-100 hover:bg-white px-6 text-grey-400 font-space py-2.5 rounded-[4px] text-xs uppercase tracking-wider duration-100 flex justify-center items-center gap-3',
    [ButtonVariants.BORDER]: `bg-none hover:bg-primary border-2 border-primary px-6 text-primary hover:text-black font-space py-2.5 rounded-[4px] text-xs uppercase tracking-wider duration-100 flex justify-center gap-3`,
    [ButtonVariants.GHOST]: `bg-none hover:bg-white/10 px-6 text-white font-space py-2.5 rounded-[4px] text-xs uppercase tracking-wider duration-150 flex justify-center gap-3`,
}

const Button = ({ children, ...props }) => {
    const { className, disabled, variant = ButtonVariants.PRIMARY } = props
    return (
        <button
            {...props}
            className={classNames(
                disabled ? 'opacity-50 pointer-events-none' : 'opacity-100',
                BUTTON_STYLES[variant],
                className
            )}
        >
            {children}
        </button>
    )
}

export default Button
