import { ReactComponent as Logo } from '../../assets/svg/logo.svg'
import { ReactComponent as Clipboard } from '../../assets/svg/copy.svg'
import { truncate } from '../../utils/helper'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../utils/hooks/auth'
import ConfirmDialog from '../ConfirmDialog'
import useModal from '../../utils/hooks/useModal'

const Profile = ({ username }) => {
    const { user, logout } = useAuth()
    const { t } = useTranslation()
    const { isOpen, closeModal, openModal } = useModal()

    const copyToClipBoard = async (copyMe) => {
        if (copyMe.length > 0) {
            try {
                await navigator.clipboard.writeText(copyMe)
                toast(t('copy_clipboard_wallet'), {
                    type: 'success',
                    autoClose: 3000,
                })
            } catch (err) {
                handleError('copy_clipboard_wallet_failed')
            }
        } else {
            handleError('error_no_wallet_to_copy')
        }
    }

    const handleError = (error) =>
        toast(t(error), {
            type: 'error',
            autoClose: 3000,
        })

    return (
        <div className="flex flex-col self-center items-center lg:py-12 ">
            <div className="relative mt-6 lg:mt-0">
                <Logo className="w-24 h-24" />
            </div>
            {!!user && (
                <div className="flex flex-col items-center">
                    <div
                        className="pt-4 flex flex-row items-center mt-1 text-grey-200 hover:text-primary cursor-pointer label-wallet"
                        onClick={() => copyToClipBoard(user?.address)}
                    >
                        <span className="text-3xs font-space font-medium mr-2 tracking-wider">
                            {truncate(user?.address, 12)}
                        </span>
                        <Clipboard className="" />
                    </div>
                    {username && (
                        <div className="pt-1 flex flex-row items-center text-center mt-1 text-grey-200">
                            {username}
                        </div>
                    )}
                    <div
                        className="text-label-sm text-grey-300 mt-4 uppercase font-mono tracking-widest cursor-pointer hover:text-primary"
                        onClick={openModal}
                    >
                        {t('sign_out')}
                    </div>
                </div>
            )}
            <ConfirmDialog
                title={t('sign_out')}
                body={t('sign_out_description')}
                confirmText={t('sign_out')}
                onConfirm={logout}
                isOpen={isOpen}
                onClose={closeModal}
            />
        </div>
    )
}

export default Profile
