import { memo, useEffect, useRef, useState } from 'react'
import { useHodl } from '../../utils/hooks/useHodl'
import _ from 'lodash'
import {
    animateText,
    convertToInternationalCurrencySystem,
    formatNumber,
} from '../../utils/helper'
import gsap from 'gsap'
import { ReactComponent as Diamond } from '../../assets/svg/diamond.svg'
import { useAuth } from '../../utils/hooks/auth'

const Tier = ({ className }) => {
    const { user, rates } = useAuth()
    const totalBalance = useRef()
    const rfrmBalance = useRef()
    const erfrmBalance = useRef()
    const bar = useRef()
    const { currentTier, tiers, getTier, maxLevel } = useHodl()
    const [level, setLevel] = useState(0)
    const balance = parseFloat(user?.rfrm) || 0
    const escrow = parseFloat(user?.erfrm) || 0

    useEffect(() => {
        if (!_.isNull(currentTier)) {
            setLevel(tiers.indexOf(currentTier))
        }
    }, [currentTier, tiers])

    useEffect(() => {
        if (balance > 0) {
            getTier(balance)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [balance])

    useEffect(() => {
        if (balance > 0 && !_.isNull(balance)) {
            animateText(
                totalBalance.current,
                parseFloat(balance) + parseFloat(escrow)
            )
            animateText(rfrmBalance.current, balance)
            animateText(erfrmBalance.current, escrow)

            // gsap.fromTo(bar.current,
            //     {width: 0},
            //     {
            //         duration: 1,
            //         width: `${(maxLevel ? 1 : balance/_.get(tiers[level+1], 'amount')) * 100}%`,
            //         ease: 'power1.out'})
        }
    }, [level, balance, maxLevel, tiers])

    const valueFormatted = (value) => (
        <span className="text-sm font-mono">
            <span className="text-input text-grey-100 mr-2">$</span>
            {convertToInternationalCurrencySystem(value)}
        </span>
    )

    const getLevel = () =>
        !maxLevel
            ? `${(
                  _.get(tiers[level + 1], 'amount') - balance.toFixed(0)
              ).toLocaleString()} RFRM FOR LEVEL ${level + 2}`
            : 'YOU ARE AT MAX LEVEL'

    const ProgressBar = ({ percentage }) => (
        <div className="flex flex-col justify-start w-full mt-6">
            <div className="h-[6px] w-full bg-grey-600 rounded-lg relative flex flex-row justify-between items-center">
                <div
                    className={`flex items-center justify-end h-[10px] bg-primary rounded-lg absolute`}
                    ref={bar}
                    style={{ width: `${percentage * 100}%` }}
                >
                    <Diamond className="mr-0.5" />
                </div>
            </div>
            {!!currentTier && (
                <span className="text-grey-300 font-mono text-label-sm mt-[14px] tracking-widest">
                    {getLevel()}
                </span>
            )}
        </div>
    )

    return (
        <div
            className={`flex flex-col items-start w-full pl-[34px] pr-8 md:my-6 lg:my-10 mt-6 lg:mt-0 transition-all `}
        >
            <div className="border-t border-grey-600 w-full h-0" />
            <div className="py-4 w-full flex flex-row justify-between">
                <span className="text-label text-gray-300 uppercase tracking-wider block">
                    Total Balance
                </span>
                <h3
                    className="text-input-lg font-space font-light text-gray-200 text-right"
                    ref={totalBalance}
                >
                    {formatNumber(
                        parseFloat(balance) + parseFloat(escrow),
                        0
                    ) || 0}
                </h3>
            </div>
            <div className="pb-4 flex flex-row justify-end items-center w-full">
                {valueFormatted(
                    (balance + escrow) * (rates?.usdc / 10 ** 6) || 0
                )}
            </div>
            <div className="border-t border-grey-600 w-full h-0" />
            <div className="py-4 w-full flex flex-row justify-between">
                <span className="text-label text-gray-300 uppercase tracking-wider block">
                    RFRM Balance
                </span>
                <h3
                    className="text-lg font-space font-light text-gray-200 text-right"
                    ref={rfrmBalance}
                >
                    {formatNumber(balance, 0) || 0}
                </h3>
            </div>
            <div className="border-t border-grey-600 w-full h-0" />
            <div className="py-4 w-full flex flex-row justify-between">
                <span className="text-label text-gray-300 tracking-wider block">
                    eRFRM BALANCE
                </span>
                <h3
                    className="text-lg font-space font-light text-gray-200 text-right"
                    ref={erfrmBalance}
                >
                    {formatNumber(escrow, 0) || 0}
                </h3>
            </div>
            {/*<ProgressBar percentage={maxLevel ? 1 : balance/_.get(tiers[level+1], 'amount')}/>*/}
        </div>
    )
}

export default memo(Tier)
