import { lazy } from 'react'

const Dashboard = lazy(() => import('../pages/Dashboard'))
const Trading = lazy(() => import('../pages/Trading'))
const Bonds = lazy(() => import('../pages/Bonds'))
const Staking = lazy(() => import('../pages/Staking'))
const Voting = lazy(() => import('../pages/Voting'))
const Leaderboard = lazy(() => import('../pages/Leaderboard'))
const AirdropChecker = lazy(() => import('../pages/AirdropChecker'))
const QuestPlatform = lazy(() => import('../pages/QuestPlatform'))
const QuestPlatformEditor = lazy(() => import('../pages/QuestPlatformEditor'))

const routes = [
    {
        path: '/',
        exact: true,
        menuItem: true,
        useLayout: true,
        title: 'Overview',
        element: <Dashboard />,
    },
    // {
    //     path: '/soon1',
    //     exact: true,
    //     menuItem: true,
    //     title: 'Soon1',
    //     element: <DashboardSoon/>
    // },
    // {
    //     path: '/soon2',
    //     exact: true,
    //     menuItem: true,
    //     title: 'Soon2',
    //     element: <Soon/>
    // },
    {
        path: '/trading',
        exact: true,
        menuItem: true,
        useLayout: true,
        title: 'Trading',
        element: <Trading />,
    },
    /*    {
        path: '/DAOs',
        exact: true,
        menuItem: true,
        title: 'DOAs',
        element: <Daos/>
    },*/
    {
        path: '/bonds',
        exact: true,
        menuItem: true,
        useLayout: true,
        title: 'Bonds',
        element: <Bonds />,
    },
    {
        path: '/staking',
        exact: true,
        menuItem: true,
        useLayout: true,
        title: 'Staking',
        element: <Staking />,
    },
    {
        path: '/voting',
        exact: true,
        menuItem: true,
        useLayout: true,
        title: 'Voting',
        element: <Voting />,
    },

    // {
    //     path: '/leaderboard',
    //     exact: true,
    //     menuItem: true,
    //     useLayout: true,
    //     title: 'Leaderboard',
    //     element: <Leaderboard />,
    // },
    {
        path: '/airdrop-checker',
        exact: true,
        menuItem: true,
        useLayout: true,
        title: 'Airdrop checker',
        element: <AirdropChecker />,
    },
    // {
    //     path: '/quest-platform',
    //     exact: true,
    //     menuItem: true,
    //     useLayout: false,
    //     title: 'Quest Platform',
    //     element: <QuestPlatform />,
    // },
    // {
    //     path: '/quest-platform-editor',
    //     exact: true,
    //     menuItem: false,
    //     useLayout: false,
    //     title: 'Quest Platform Editor',
    //     element: <QuestPlatformEditor />,
    // },
]

export default routes
