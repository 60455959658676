import { useQuery } from '@tanstack/react-query'
import { gql, GraphQLClient } from 'graphql-request'
import { craftAPI } from '../../config/endpoints'

const graphqlClient = new GraphQLClient(craftAPI)
graphqlClient.setHeader('Authorization', `Bearer x-INsrzDbNgF_-s_DvyyNWLBBUooE4RE`)

function useListNews() {
    return useQuery({
        queryKey: ['news'],
        queryFn: async () => {
            return await graphqlClient.request(
                gql`
                    query NewsQuery {
                        entries(section: "news", site: "dashboard", orderBy:"lft") {
                            title
                            postDate
                            ... on news_default_Entry {
                                source
                                referenceUrl
                            }
                        }
                    }
                `
            )
        }
    })
}

function useListUpdates() {
    return useQuery({
        queryKey: ['updates'],
        queryFn: async () => {
            return await graphqlClient.request(
                gql`
                    query Updates {
  entries(section: "updates", site: "dashboard") {
    title
    ... on updates_default_Entry {
     image {
        url,
        title
      },
     backgroundMedia {
        url,
        title
      },
      title
           category {
        title
      },
      button {
        url,
        text
      }
    }
  }
}
                `
            )
        }
    })
}

function useListTradeReports() {
    return useQuery({
        queryKey: ['tradeReports'],
        queryFn: async () => {
            return await graphqlClient.request(
                gql`
                    query TradeReports {
                      entries(section: "tradeReports", site: "dashboard") {
                        title
                        postDate
                        ... on tradeReports_default_Entry {
                          button {
                            text
                            url
                          }
                        }
                      }
                    }
                `
            )
        }
    })
}

export {
    useListNews,
    useListUpdates,
    useListTradeReports
}
