import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ReactComponent as Close } from '../../assets/svg/close.svg'

const Modal = ({ onClose, isOpen, children, customClass, hideCloseButton = false}) => {
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[100]" onClose={onClose}>

                <Transition.Child
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-70"/>
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className={`relative md:w-3/6 max-w-2xl ${customClass}`}>
                                <div className="flex flex-row overflow-hidden">
                                    {children}
                                    {!hideCloseButton ? <button
                                        onClick={onClose}
                                        className="flex mt-1 -ml-16 sm:ml-1 z-100 relative font-mono shrink-0 text-sm w-16 h-16 bg-transparent sm:bg-primary flex items-center justify-center">
                                        <Close/>
                                    </button> : <div className="w-16 ml-1"></div>}
                                </div>
                                <div className="ml-[1px] mt-[2px] h-[calc(100%_-_10px)] w-[2px] bg-primary absolute top-2 right-[-5px] sm:right-[62px]"/>
                                <div className="ml-[2px] mt-[4px] w-[calc(100%_+_2px)] sm:w-[calc(100%_-_66px)] h-[2px] bg-primary"/>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default Modal
