import { createContext, useContext, useRef } from 'react'
import { useWeb3ModalProvider } from '@web3modal/ethers/react'
import { BrowserProvider, ethers, isBytesLike } from 'ethers'

export const NotificationTopic = Object.freeze({
    DEPOSIT: 'deposit',
    WITHDRAW: 'withdraw',
    CLAIM: 'claim',
    PURCHASE: 'purchase',
})

const Web3Context = createContext(null)

const Web3Provider = ({ children }) => {
    const { walletProvider } = useWeb3ModalProvider()
    const ethersProvider = new BrowserProvider(walletProvider)

    const notifier = useRef(null)
    const setNotifier = f => notifier.current = f
    const notify = (topic) => {
        if (notifier.current) {
            notifier.current(topic)
        }
    }

    const changeNetwork = async () => {
        const chainId = process.env.REACT_APP_CHAIN_ID
        if (window.ethereum.networkVersion !== chainId) {
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: ethers.toQuantity(chainId) }]
                });
            } catch (err) {
                console.log(err)
            }
        }

        if (window.ethereum) {
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: chainId }],
                })
            } catch (error) {
                console.error(error)
            }
        }
    }

    const subscribeToEvents = async () => {
        ethersProvider.on('network', (e) => {
             console.info(`connected to network '${e.name}'`)
        })
    }

    return (
        <Web3Context.Provider value={{
            changeNetwork,
            subscribeToEvents,
            setNotifier,
            notify,
        }}>
            {children}
        </Web3Context.Provider>
    )
}

const useWeb3 = () => {
    const context = useContext(Web3Context)
    if (context === undefined) {
        throw new Error('useWeb3 must be used within a Web3Provider')
    }

    const { walletProvider } = useWeb3ModalProvider()
    const ethersProvider = new BrowserProvider(walletProvider)

    const send = async (to, data, value, meta = {}, topic = null) => {
        const signer = await ethersProvider.getSigner()

        if (!!context?.network && context?.network !== process.env.REACT_APP_CHAIN_ID) {
            throw new Error('Please connect to the correct network')
        }

        data = data.startsWith('0x') ? data : `0x${data}`
        if (!isBytesLike(data)) {
            throw new Error('invalid data format')
        }

        const { hash } = await signer.sendTransaction({
            data,
            to,
            value,
            ...meta,
            from: context?.address,
        })

        await ethersProvider.waitForTransaction(hash)
        const rcpt = await ethersProvider.getTransactionReceipt(hash)
        if (rcpt.status < 1) {
            throw new Error('transaction reverted')
        }
        if (topic) {
            context.notify(topic)
        }
    }

    const sendBatch = async (transactions, topic) => {
        const _transactions = transactions.sort((a, b) =>
            a?.nonce > b?.nonce ? 1 : b?.nonce > a?.nonce ? -1 : 0
        )
        for (let i = 0; i < _transactions?.length; i++) {
            const { to, data, value } = _transactions[i]
            await send(to, data, value, {},
                i + 1 === _transactions?.length ? topic : null)
        }
    };

    return { ...context, sendBatch }
}


export { useWeb3, Web3Provider }
