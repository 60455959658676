import {createContext, useContext} from 'react'
import {useQuery} from '@tanstack/react-query'
import {apiCall} from '../api'
import {useAuth} from './auth'
import { useWeb3, NotificationTopic } from './web3'
import toast from 'react-hot-toast'

const Staking = createContext(null)

const StakingProvider = ({ children }) => {
    const { tokens } = useAuth()

    /**
     * List deposited stakes
     * @returns {UseQueryResult<any, unknown>}
     */
    const listStakes = () =>
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useQuery({
            queryKey: ['listStakes'],
            queryFn: async () =>
                await apiCall(
                    '/staking/stakes',
                    {
                        method: 'GET',
                    },
                    tokens
                ),
            enabled: !!tokens.access,
            staleTime: Infinity,
            onError: (error) => {
                toast.error(
                    `An error occurred. Couldn't list stakes. ${
                        error.response?.data?.message || error.message
                    }`,
                    {
                        duration: 5000,
                    }
                )
            },
        })

    /**
     * List staking pools
     * @returns {UseQueryResult<any, unknown>}
     */
    const listPools = () =>
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useQuery({
            queryKey: ['listPools'],
            queryFn: async () =>
                await apiCall(
                    '/staking/pools',
                    {
                        method: 'GET',
                    },
                ),
            onError: (error) => {
                toast.error(
                    `An error occurred. Couldn't list pools. ${
                        error.response?.data?.message || error.message
                    }`,
                    {
                        duration: 5000,
                    }
                )
            },
        })

    const listPoolsRewards = () =>
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useQuery({
            queryKey: ['listStakingRewards'],
            queryFn: async () =>
                await apiCall(
                    '/staking/rewards',
                    {
                        method: 'GET',
                    },
                    tokens
                ),
            enabled: !!tokens.access,
            onError: (error) => {
                toast.error(
                    `An error occurred. Couldn't list staking rewards. ${
                        error.response?.data?.message || error.message
                    }`,
                    {
                        duration: 5000,
                    }
                )
            },
        })
/*

    const { data, isPending } = useQuery(['trades'], () => getTrades(), {
        enabled: true,
        staleTime: Infinity
    })

 */
    /**
     * List claimed rewards
     * @returns {UseQueryResult<any, unknown>}
     */
    const listClaims = () =>
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useQuery({
            queryKey: ['listClaims'],
            queryFn: async () =>
                await apiCall(
                    '/staking/claims',
                    {
                        method: 'GET',
                    },
                    tokens
                ),
            staleTime: Infinity,
            enabled: !!tokens.access,
            onError: (error) => {
                toast.error(
                    `An error occurred. Couldn't list claims. ${
                        error.response?.data?.message || error.message
                    }`,
                    {
                        duration: 5000,
                    }
                )
            },
        })

    return (
        <Staking.Provider value={{ listPools, listStakes, listClaims, listPoolsRewards }}>
            {children}
        </Staking.Provider>
    )
}

const useStaking = () => {
    const context = useContext(Staking)
    const { tokens } = useAuth()
    const { sendBatch } = useWeb3()

    if (context === undefined) {
        throw new Error('useStaking must be used within a StakingProvider')
    }

    const stringVal = (n) => `${n || 0}`

    const addStake = async (data) => {
        try {
            const transactions = await apiCall(
                '/staking',
                {
                    payload: {
                        ...data,
                        token_id: data?.token_id,
                    },
                    method: 'POST',
                },
                tokens
            )
            await sendBatch(transactions, NotificationTopic.DEPOSIT)
        } catch (error) {
            toast.error(
                `Couldn't add stake. ${ process.env.REACT_APP_HOST_ENV === 'dev' ?
                    error.response?.data?.message || error.message : ''
                }`,
                {
                    duration: 5000,
                }
            )
            //console.error(`could not add stake: ${error}`)
        }
    }

    const removeStake = async (data) => {
        try {
            return await apiCall(
                '/staking',
                {
                    payload: {
                        stake_id: data?.id || 0,
                        type: data?.type,
                        amount: stringVal(data?.deposit / 10 ** 18),
                        lock_id: parseInt(data?.lock_id),
                        token_id: stringVal(data?.token_id),
                    },
                    method: 'DELETE',
                },
                tokens
            )
        } catch (error) {
            toast.error(
                `An error occurred. Couldn't remove stake. ${ process.env.REACT_APP_HOST_ENV === 'dev' ?
                    error.response?.data?.message || error.message : ''
                }`,
                {
                    duration: 5000,
                }
            )
            //console.error(`could not remove stake: ${error}`)
        }
    }

    const claimRewards = async (type) => {
        try {
            const transactions = await apiCall(
                '/staking/claim',
                {
                    payload: { type },
                    method: 'POST',
                },
                tokens
            )
            await sendBatch(transactions, NotificationTopic.CLAIM)
        } catch (error) {
            toast.error(
                `An error occurred. Couldn't claim reward. ${ process.env.REACT_APP_HOST_ENV === 'dev' ?
                    error.response?.data?.message || error.message : ''
                }`,
                {
                    duration: 5000,
                }
            )
            //console.error(`could not claim rewards: ${error}`)
        }
    }

    const claimVesting = async (data) => {
        try {
            const transactions = await apiCall(
                `/staking/claims/${data?.id}/claim`,
                { method: 'POST' },
                tokens
            )
            await sendBatch(transactions)
        } catch (error) {
            toast.error(
                `An error occurred. Couldn't claim vesting. ${ process.env.REACT_APP_HOST_ENV === 'dev' ?
                    error.response?.data?.message || error.message : ''
                }`,
                {
                    duration: 5000,
                }
            )
        }
    }

    return { ...context, addStake, removeStake, claimRewards, claimVesting }
}

export { useStaking, StakingProvider }
