import { ReactComponent as A } from '../assets/svg/tiers/01.svg'
import { ReactComponent as B } from '../assets/svg/tiers/02.svg'
import { ReactComponent as C } from '../assets/svg/tiers/03.svg'
import { ReactComponent as D } from '../assets/svg/tiers/04.svg'
import { ReactComponent as E } from '../assets/svg/tiers/05.svg'
import { ReactComponent as F } from '../assets/svg/tiers/06.svg'
import { ReactComponent as G } from '../assets/svg/tiers/07.svg'
import { ReactComponent as H } from '../assets/svg/tiers/08.svg'
import { ReactComponent as I } from '../assets/svg/tiers/09.svg'
import { ReactComponent as J } from '../assets/svg/tiers/10.svg'
import { ReactComponent as K } from '../assets/svg/tiers/11.svg'
import { ReactComponent as L } from '../assets/svg/tiers/12.svg'
import { ReactComponent as M } from '../assets/svg/tiers/13.svg'

const tiers = [
    {
        "title": "Hodlnaut",
        "amount": 0,
        "badge": <A/>
    },
    {
        "title": "Hodlnaut First Class",
        "amount": 5000000,
        "badge": <B/>
    },
    {
        "title": "Senior Hodlnaut",
        "amount": 10000000,
        "badge": <C/>
    },
    {
        "title": "Staff Sergeant ",
        "amount": 20000000,
        "badge": <D/>
    },
    {
        "title": "Technical Sergeant",
        "amount": 30000000,
        "badge": <E/>
    },
    {
        "title": "Master Sergeant",
        "amount": 40000000,
        "badge": <F/>
    },
    {
        "title": "Starship Master Sergeant",
        "amount": 50000000,
        "badge": <G/>
    },
    {
        "title": "Senior Master Sergeant",
        "amount": 75000000,
        "badge": <H/>
    },
    {
        "title": "Starship Senior Master Sergeant",
        "amount": 100000000,
        "badge": <I/>
    },
    {
        "title": "Chief Master Sergeant",
        "amount": 125000000,
        "badge": <J/>
    },
    {
        "title": "Starship Chief Master Sergeant",
        "amount": 150000000,
        "badge": <K/>
    },
    {
        "title": "Command Chief Master Sergeant",
        "amount": 200000000,
        "badge": <L/>
    },
    {
        "title": "Chief Master Sergeant of Hodl",
        "amount": 250000000,
        "badge": <M/>
    },
]

export {
    tiers
}
