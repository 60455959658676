import loader from '../assets/img/loader.gif'

const Loader = ({ width = 34 }) => {
    return (
        <div className="w-full h-full flex items-center justify-center">
            <img src={loader} width={width} alt="loader gif" />
        </div>
    )
}

export default Loader
