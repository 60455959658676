import { useField } from 'formik'
import {useRef, useState, useMemo, useEffect} from 'react'
import _ from 'lodash';
import useEventListener from '../../utils/hooks/useEventListener';

const RangeItem = ({ label, data, onSlide, ...props }) => {
    const [field, meta] = useField(props)
    const slider = useRef()
    const [width, setWidth] = useState(field.value)

    //console.log(data, data.label)
    const idx = _.findIndex(data, (item) => item?.value === parseInt(field.value))

    useEffect(() => {
        if (slider.current) {
            setWidth((idx/(data.length - 1)) * slider.current.clientWidth)
        }
    }, [slider.current])

    useEventListener('resize', () => {
        if (slider.current) {
            setWidth((idx/(data.length - 1)) * slider.current.clientWidth)
        }
    })

    const { label: _label } = useMemo(() => {
        return data ? data[idx] : null //data?.find(d => d.value === field.value + 1) || {}
    },[data, field.value])

    return (
        <div className="w-full flex items-center justify-between border-t-[1px] border-grey-300 pt-2 mb-4">
            <label htmlFor={field.name} className="text-label uppercase">
                {label}
            </label>
            <div className="w-7/12 flex flex-col">
                <div className="bg-grey-400 px-4 h-[37px] flex items-center relative">
                    <input
                        ref={slider}
                        {...field}
                        {...props}
                        className={field.className ? field.className : "w-full bg-primary slider"}
                        list="tickmarks"
                        onInput={(e) => {
                            const idx = _.findIndex(data, (item) => _.get(item ,'value') === parseInt(e.target.value))
                            setWidth((idx/(data.length - 1)) * slider.current.clientWidth)
                        }}
                    />
                    <div
                        className="top-[16px] h-[4px] flex items-center bg-primary absolute rounded pointer-events-none"
                        style={{width: width}}
                    />
                    <ul className="flex justify-between w-full absolute top-[12px] left-0 select-none px-4 pointer-events-none h-[10px]">
                        {data.map((item, index) =>  (index === 0 || index === data.length - 1)
                            ? <li key={`${index + item.value}`} className="flex justify-center relative"><span/></li>
                            : <li key={`${index + item.value}`} className="flex justify-center relative"><span className="pointer-events-none absolute w-[3px] h-[9px] bg-grey-400"/></li>
                        )}
                    </ul>
                </div>
                <div className="px-4 h-[37px] w-full bg-primary flex items-center text-label xl:text-input">
                    {_label}
                </div>
            </div>
            {meta.touched && meta.error ? <span className="text-primary text-2xs">{meta.error}</span> : null}
        </div>
    )
}

export default RangeItem
