import TradeReport from './TradeReport'
import { useTranslation } from 'react-i18next'
import Gradient from '../Gradient'
import { useListTradeReports } from '../../utils/hooks/useRequest'
import ErrorMessage from '../ErrorMessage'
import Loader from '../Loader'
import NoData from '../NoData'

const Reports = () => {
    const { t } = useTranslation()
    const { data, error, isFetching, refetch } = useListTradeReports()

    if (!!error)
        return (
            <div className="relative flex h-full min-h-[20rem] w-full flex-1 mt-8 sm:mt-0 sm:w-[22rem] lg:w-full flex flex-col bg-grey-600 overflow-hidden text-center">
                <ErrorMessage callback={refetch} />
            </div>
        )

    return (
        <div className="relative flex h-full min-h-[20rem] w-full flex-1 mt-2 sm:mt-0 sm:w-[22rem] lg:w-full flex flex-col bg-grey-600 overflow-hidden">
            {isFetching ? (
                <div className="flex items-center flex-1">
                    {' '}
                    <Loader />
                </div>
            ) : (
                <>
                    <h2 className="p-8 pb-4 text-title text-white">
                        {t('title_trade_reports')}
                    </h2>
                    <div className="overflow-auto px-8 scroller">
                        {data ? (
                            data?.entries.map((item, index) => {
                                return (
                                    <TradeReport
                                        key={index}
                                        index={index}
                                        latest={index === 0}
                                        title={item?.title}
                                        url={item?.button?.url}
                                        timestamp={item?.postDate}
                                    />
                                )
                            })
                        ) : (
                            <NoData callback={refetch} />
                        )}
                    </div>
                    <Gradient />
                </>
            )}
        </div>
    )
}

export default Reports
