import { ReactComponent as Logo } from '../../assets/svg/logo.svg'
import {Link} from 'react-router-dom';

const NavigationBar = ({children}) => {
    return (
        <div className="w-full h-16 bg-grey-600 flex flex-row items-center justify-start">
            <Link to={"/"} className="px-3 border-r-2 border-r-grey-500 h-full flex items-center">
                <Logo className="w-10 h-10"/>
            </Link>
            {children}
        </div>
    )
}

export default NavigationBar
