import NewsItem from './NewsItem'
import { useTranslation } from 'react-i18next'
import Gradient from '../Gradient'
import { DateTime } from 'luxon'
import Loader from '../Loader'
import { useListNews } from '../../utils/hooks/useRequest'
import ErrorMessage from '../ErrorMessage'
import NoData from '../NoData'
import {useEffect, useState} from 'react';
import _ from 'lodash';

const News = () => {
    const { t } = useTranslation()
    const { data, error, isFetching, refetch } = useListNews()
    const [news, setNews] = useState([])

    useEffect(() => {
        if (data) {
            const o = _.orderBy(data?.entries, ['postDate'], ['desc'])
            setNews(o)
        }
    }, [data?.entries])

    if (!!error) return (
        <div className="relative flex h-full min-h-[20rem] w-full flex-1 mt-2 sm:mt-0 sm:w-[22rem] lg:w-full flex flex-col bg-grey-600 overflow-hidden text-center">
            <ErrorMessage callback={refetch}/>
        </div>
    )

    return (
        <div className="relative flex flex-col h-full min-h-[20rem] w-full flex-1 mt-2 sm:mt-0  bg-grey-600 overflow-hidden">
            {isFetching ? <div className="flex items-center flex-1"> <Loader/></div> :
                <>
                    <h2 className="p-8 text-title text-white">{t('title_news')}</h2>
                    <div className="lg:overflow-auto space-y-4 scroller">
                        {news?.length > 0 ? news?.map((item, index) =>
                            <NewsItem
                                key={index}
                                title={item?.title}
                                author={item?.source}
                                timestamp={DateTime.fromISO(item?.postDate).toFormat("dd-MM-yyyy")}
                                url={item?.referenceUrl}
                                index={index}
                            />) : <NoData callback={refetch}/> }
                    </div>
                    <Gradient/>
                </>}
        </div>
    )
}

export default News
