import React, {useEffect, useReducer, useState} from 'react'
import axios from 'axios'
import _ from 'lodash'
import {tiers} from '../../config/tiers'
import {useQuery} from '@tanstack/react-query'
import {gql, GraphQLClient} from 'graphql-request'
import {craftAPI, RFRM_PROD_URL} from '../../config/endpoints'
import {apiCall} from '../api'

const graphqlClient = new GraphQLClient(craftAPI)
graphqlClient.setHeader('Authorization', `Bearer x-INsrzDbNgF_-s_DvyyNWLBBUooE4RE`)

const HodlContext = React.createContext()

const initialState = {
    currentTier: null,
    tiers: tiers,
    maxLevel: false,
    modals: []
}

const hodlReducer = (state, action) => {
    switch (action.type) {
        case 'GET_CURRENT_TIER':
            const _currentTier = action.payload
            const _maxTier = _.get(tiers, tiers.length - 1, null)
            return {
                ...state,
                currentTier: action.payload,
                maxLevel: _currentTier === _maxTier
            }
        case 'RESET':
            return initialState
        default:
            return state
    }
}

const HodlProvider = ({children}) => {

    const baseUrl = RFRM_PROD_URL //'https://api.hfd.dev.growity.app/'
    const [state, dispatch] = useReducer(hodlReducer, initialState)
    const [modals, setModals] = useState([])

    const getTrades = async () => {
        const { data } = await axios.get(baseUrl + 'info/cached-trades')
        return data
    }

    const getTVL = async () => {
        const { data } = await axios.get(baseUrl + 'info/total-value-locked')
        return data
    }

    const getRFRMCirculatingSupply = async () => {
        const { data } = await axios.get(baseUrl + 'info/hft-circulating-supply')
        return data
    }

    const getTotalTrades = async () => {
        const { data } = await axios.get(baseUrl + 'info/last-total-volume')
        return data
    }

    const getTotalVolume = async () => {
        const { data } = await axios.get(baseUrl + 'info/total-volume?calculationType=seconds')
        return data
    }

    const getTradingTreasury = async () => {
        const { data } = await axios.get(baseUrl + 'info/trading-treasury')
        return data
    }

    const getTreasury = async () => {
        const data = await apiCall(
            '/treasury',
            {
                method: 'GET',
            })
        return data
    }

    const getTreasuryStats = async () => {
        const data = await apiCall(
            '/overview',
            {
                method: 'GET',
            })
        return data
    }

    const getTVLChart = async () => {
        const data = await apiCall(
            '/charts/tvl?interval=1d',
            {
                method: 'GET',
            })
        return data?.items
    }

    const getTradingChart = async () => {
        const data = await apiCall(
            '/charts/trades?interval=1h',
            {
                method: 'GET',
            })
        return data?.items
    }

    const fetchModals = async () => {
        return graphqlClient.request(
            gql`
                query ModalReports {
                    entries(section: "modals", site: "dashboard") {
                        title
                        ... on modals_default_Entry {
                            body,
                            position
                       }
                    }
                }
            `
        )
    }

    function getNewsConfig() {
        return graphqlClient.request(
            gql`
                query NewsCarousel {
                    entries(section:"newsCarousel", limit:1, site:"dashboard") {
                        ...on newsCarousel_newsCarousel_Entry {
                            backgroundVisualId,
                            wave,
                            warp,
                            diagonal,
                            straight 
                        }
                    }
                }
            `
        )
    }

    const getProposalsConfig = async () => {
        return graphqlClient.request(
            gql`
                query VotingCarousel {
                    entries(section: "votingCarousel", limit:1, site: "dashboard") {
                        ... on votingCarousel_votingCarousel_Entry {
                            backgroundVisualId,
                            wave,
                            warp,
                            diagonal,
                            straight 
                        }
                    }
                }
            `
        )
    }

    const listProposals = async () => {
        return graphqlClient.request(
            gql`
                query Votes {
                    entries(section: "voting", site: "dashboard") {
                        title
                        ... on voting_vote_Entry {
                            image {
                                url,
                                title
                            },
                            backgroundMedia {
                                url,
                                title
                            },
                            title
                            subtitle,
                            referenceUrl
                            currentStatus
                            closingDate
                            image {
                                alt
                                url @transform(width: 300, immediately: true, mode: "crop")
                            }
                        }
                    }
                }
            `
        )
    }

    const { data } = useQuery({
        queryKey: ['modal'],
        queryFn: fetchModals
    })


    useEffect(() => {
        if(!data) return
        setModals(data?.entries)
    }, [data])

    const getTier = (balance) => {
        const _tier = _.find(tiers, (tier, index) => {
            if (index < tiers.length - 1) {
                return balance >= tier.amount && balance < tiers[index + 1].amount
            }
            return tier
        })

        dispatch({
            type: 'GET_CURRENT_TIER',
            payload: _tier
        })
    }

    return (
        <HodlContext.Provider
            value={{
                ...state,
                modals,
                getTradingTreasury,
                getTier,
                getTVL,
                getRFRMCirculatingSupply,
                getTotalTrades,
                getTotalVolume,
                getTrades,
                getTreasury,
                getTreasuryStats,
                getNewsConfig,
                getProposalsConfig,
                listProposals,
                getTVLChart,
                getTradingChart
            }}
        >
            {children}
        </HodlContext.Provider>
    )
}

const useHodl = () => {
    const context = React.useContext(HodlContext)
    if (context === undefined) {
        throw new Error('useHodl must be used within a HodlProvider')
    }
    return context
}

export {HodlProvider, useHodl}
