import {useEffect, useState} from 'react'
import toast, { useToaster } from 'react-hot-toast'
import {useWeb3} from '../utils/hooks/web3'
import {useTranslation} from 'react-i18next'
import {chains} from '../config/constants'
import _ from 'lodash'
import {useWeb3ModalAccount} from '@web3modal/ethers/react';

const Notifications = () => {
    const { t } = useTranslation()
    const { toasts, handlers } = useToaster()
    const { startPause, endPause, calculateOffset, updateHeight } = handlers
    const [height, setHeight] = useState(0)
    const [width, setWidth] = useState(0)
    const { chainId } = useWeb3ModalAccount()

    useEffect(() => {
        if (!!chainId) {
            const n = chains[parseInt(chainId)] || ''
            const r = chains[parseInt(parseInt(process.env.REACT_APP_CHAIN_ID))] || ''

            chainId === parseInt(process.env.REACT_APP_CHAIN_ID)
                ? toast.success(t('chain_changed_correct'))
                : toast.error(
                    t('chain_changed_incorrect', { chain: _.capitalize(n), requiredChain: _.capitalize(r) }), {
                        duration: 5000,
                    }
                )
        }
    }, [chainId])

    return (
        <div
            style={{
                position: "fixed",
                top: 10,
                left: 10
            }}
            onMouseEnter={startPause}
            onMouseLeave={endPause}
        >
            {toasts.map((toast) => {
                const offset = calculateOffset(toast, {
                    reverseOrder: false,
                    gutter: 8
                })

                const ref = (el) => {
                    if (el && !toast.height) {
                        const height = el.getBoundingClientRect().height
                        updateHeight(toast.id, height)
                        setHeight(height)

                        setWidth(el.getBoundingClientRect().width)
                    }
                }

                const getColor = () => {
                    switch (toast.type) {
                        case 'success':
                        case 'custom':
                            return '#00FFC2'
                        case 'error':
                            return '#FF0064'
                        default:
                            return '#B2B1AD'
                    }
                }

                return (
                    <div className="absolute"
                         style={{
                             transition: "all 0.2s",
                             transform: `translateY(${offset + 70}px)`,
                             opacity: toast.visible ? 1 : 0
                         }}
                         key={toast.id}
                    >
                        <div
                            ref={ref}
                            className="w-full max-w-[320px] bg-grey-200 rounded-none w-max z-40 px-8 py-6 top-0 absolute font-sans"
                        >
                            <span className="whitespace-pre-wrap">{toast.message}</span>
                        </div>
                        <div
                            className={`w-[${Math.ceil(width)}px] h-[${height}px] absolute top-1 left-1`}
                            style={{width: width, height: height, backgroundColor: getColor()}}
                        />
                        <div
                            className={`bg-gray-900 absolute top-0.5 left-0.5`}
                            style={{width: width, height: height}}
                        />

                    </div>

                )
            })}
        </div>
    )
}

export default Notifications
