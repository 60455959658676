import { useField } from 'formik'
import { classNames } from '../../utils/helper'

const SelectItem = ({
    label,
    defaultValue,
    options,
    id,
    className,
    ...props
}) => {
    if (!options) return <></>

    return (
        <div className="w-full flex items-center justify-between border-grey-300 mb-4">
            {label && (
                <label htmlFor={id} className="text-label uppercase ">
                    {label}
                </label>
            )}
            <select
                {...props}
                className={classNames(
                    className,
                    'leading-[18px] text-grey-100 focus:outline-primary icon-select px-4 form-item min-h-[37px] h-fit w-7/12 bg-grey-400 text-input'
                )}
                name={id}
                id={id}
                defaultValue={defaultValue}
            >
                {options.map(({ value, label }) => (
                    <option
                        className="text-grey-100 rounded-none focus:rounded-none"
                        key={value}
                        value={value}
                    >
                        {label}
                    </option>
                ))}
            </select>
        </div>
    )
}

export default SelectItem
