import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './utils/i18n'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import Loader from './components/Loader'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Web3Provider } from './utils/hooks/web3'
import { AuthProvider } from './utils/hooks/auth'
import { HodlProvider } from './utils/hooks/useHodl'
import { BondingProvider } from './utils/hooks/bonding'
import { StakingProvider } from './utils/hooks/staking'
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import Notifications from './components/Notifications'
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = '0a4cb228fe093a67981e67bf0bba1d4a'

// 2. Set chains
const mainnet = {
    chainId: 1,
    name: 'Ethereum',
    currency: 'ETH',
    explorerUrl: 'https://etherscan.io',
    rpcUrl: 'https://cloudflare-eth.com',
}

const sepolia = {
    chainId: 11155111,
    name: 'Sepolia',
    currency: 'ETH',
    explorerUrl: 'https://sepolia.etherscan.io',
    rpcUrl: 'https://sepolia.infura.io/v3/dc6c68ab5a66439aacd6f4d05aaab711',
}

// 3. Create modal
const metadata = {
    name: 'ReformDAO',
    description: 'ReformDAO Dashboard',
    url: 'https://dashboard.reformdao.com', // origin must match your domain & subdomain
    // icons: ['https://avatars.mywebsite.com/']
}

createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [mainnet, sepolia],
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
})

const root = ReactDOM.createRoot(document.getElementById('root'))

const queryClient = new QueryClient({
    defaultOptions: {
        refetchOnMount: false,
    },
})

const gtmParams = {
    id: 'GTM-PSRZ2VT',
}

if (process.env.REACT_APP_HOST_ENV === 'prod') {
    console.log = () => {}
}

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <React.Suspense
                fallback={
                    <div className="flex items-center justify-center h-full">
                        <Loader />
                    </div>
                }
            >
                <GTMProvider state={gtmParams}>
                    <QueryClientProvider client={queryClient}>
                        <Web3Provider>
                            <AuthProvider>
                                <HodlProvider>
                                    <BondingProvider>
                                        <StakingProvider>
                                            <App />
                                        </StakingProvider>
                                    </BondingProvider>
                                </HodlProvider>
                            </AuthProvider>
                            <Notifications />
                        </Web3Provider>
                    </QueryClientProvider>
                </GTMProvider>
            </React.Suspense>
        </BrowserRouter>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoints. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
